import service from "@/request/index";

/**
 * @description 套餐查询
 * */
export function packageList(type) {
  return service.get(`web/product/list/${type}`);
}

/**
 * @description 银行转账下单
 * */
export function orderByBank(params) {
  return service.get(`/bank/transfer/${params.id}/${params.months}`);
}

export function bankOrderDetails(id) {
  return service.get(`/bank/transfer/${id}`);
}

/**
 * @description 支付宝支付下单
 * */
export function orderByAlipay(params) {
  return service.get(`/alipay/trade/pay/${params.id}/${params.months}`);
}

/**
 * @description 删除授权码
 * */
export function deleteAuthCode(id) {
  return service.delete(`/app/authorization-code/delete/${id}`);
}

/**
 * @description 获取授权码
 * */
export function createAuthCode(params) {
  return service.get("/app/authorization-code/gain", {
    params,
  });
}
